<template>
  <div class="home">
    <el-container>
      <el-header height="200" style="padding: 0;">
        <Nav :showSearch="false"/>
      </el-header>
      <div class="wrapper">
        <div id="building"
             style="align-self:center; text-align: center; width: 680px ;border-radius: 10px;padding-top:20px">
          <div class="title">
            <span>法人注册</span>
            <hr />
          </div>
          <div>
            <el-form ref="loginFormRef" :model="form" :rules="rules">
              <el-tabs :tab-position="center">
                <div class="container">
                  <el-form-item prop="username" label="法人类型" label-width="150px" >
                    <el-input v-model="form.username" placeholder="请选择法人类型"   >
                    </el-input>
                  </el-form-item>

                  <el-form-item prop="username" label="企业名称" label-width="150px" >
                    <el-input v-model="form.username" placeholder="请输入企业名称"   >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="username" label="信用代码" label-width="150px" >
                    <el-input v-model="form.username" placeholder="请输入信用代码"   >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="username" label="核验类别" label-width="150px" >
                    <el-input v-model="form.username" placeholder="请选择核验类别"   >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="username" label="法定身份证号码" label-width="150px" >
                    <el-input v-model="form.username" placeholder="请输入法定身份证号码"   >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="username" label="手机号码" label-width="150px" >
                    <el-input v-model="form.username" placeholder="请输入手机号码"   >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="username" label="短信验证" label-width="150px" >
                    <el-input v-model="form.username" placeholder="请输入短信验证"   >
                      <el-button slot="append" type="primary" >发送验证码</el-button>
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="username" label="登录密码" label-width="150px" >
                    <el-input v-model="form.username" placeholder="请输入登录密码"   >
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="username" label="确认密码" label-width="150px" >
                    <el-input v-model="form.username" placeholder="请再次输入确认密码"   >
                    </el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button :disabled="isLoginLoading" style="width: 100%;margin-top:10px;" type="primary"
                               @click="sendSmsCode">注 册
                    </el-button>
                  </el-form-item>
                </div>
              </el-tabs>
            </el-form>
          </div>
          <div>
          </div>
        </div>
      </div>
      <footer2/>
    </el-container>
  </div>
</template>
<script setup>
import footer2 from "components/footer.vue";
import Nav from "@/components/nav";
import {reactive, ref} from "vue";


const loginFormRef = ref();
const form = reactive({
  username: "",
  password: "",
  verifyCode: "",
  userPhone: "",

})
</script>

<style lang="scss" scoped>

.wrapper {
  /*渐变的背景色*/
  /*height: 100vh;
  background-image: linear-gradient(to bottom right, #FC466B, #3F5EF8);
  overflow: hidden;*/
  /*背景图*/
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}
.title {
  text-align: center;
  font-size: 25px;
  color: #3f3f3f;
  margin-bottom: 10px;
  span{
    z-index: 99;padding: 0px 10px; background: whitesmoke;
  }
  hr{
    margin-top: -15px; border: 1px solid black;
  }
}
::v-deep .el-form-item__label {
  border: 1px solid #DCDFE6;
  text-align: left;
  padding-left: 20px;
  border-right: 0px;
}
::v-deep .el-input__inner {
  border-radius: 0px;
  height: auto;
}

</style>
